import './ShareButtons.scss';

import cx from 'classnames';
import FacebookSvg from 'font-awesome-svg-png/black/svg/facebook.svg';
import LinkedInSvg from 'font-awesome-svg-png/black/svg/linkedin.svg';
import TwitterSvg from 'font-awesome-svg-png/black/svg/twitter.svg';
import WhatsappSvg from 'font-awesome-svg-png/black/svg/whatsapp.svg';
import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import { ORIGIN, TWITTER_HANDLE } from '~/app/Constants';

const getTextToShare = ({
  extraText,
  title,
  url,
}: {
  extraText?: string;
  title: string;
  url?: string;
}) => {
  if (url) {
    return title;
  }

  return `${title} ${extraText}`.trim();
};

interface ShareButtonsProps {
  className?: string;
  extraText?: string;
  title: string;
  tags?: string[];
  url?: string;
}

const ShareButtons = ({ className, extraText, title, tags = [], url }: ShareButtonsProps) => {
  const textToShare = getTextToShare({ extraText, title, url });
  const renderUrl = url || ORIGIN;
  return (
    <div className={cx('shareButtons', className)}>
      <FacebookShareButton
        className="shareButtons-button shareButtons-button--facebook"
        quote={textToShare}
        url={renderUrl}
      >
        <FacebookSvg className="shareButtons-buttonIcon" />
        <span className="shareButtons-buttonText">Facebook</span>
      </FacebookShareButton>
      <TwitterShareButton
        className="shareButtons-button shareButtons-button--twitter"
        hashtags={tags}
        title={textToShare}
        url={renderUrl}
        via={TWITTER_HANDLE}
      >
        <TwitterSvg className="shareButtons-buttonIcon" />
        <span className="shareButtons-buttonText">Twitter</span>
      </TwitterShareButton>
      <LinkedinShareButton
        className="shareButtons-button shareButtons-button--linkedin"
        url={renderUrl}
      >
        <LinkedInSvg className="shareButtons-buttonIcon" />
        <span className="shareButtons-buttonText">LinkedIn</span>
      </LinkedinShareButton>
      <WhatsappShareButton
        className="shareButtons-button shareButtons-button--whatsapp"
        title={textToShare}
        url={renderUrl}
      >
        <WhatsappSvg className="shareButtons-buttonIcon" />
        <span className="shareButtons-buttonText">WhatsApp</span>
      </WhatsappShareButton>
    </div>
  );
};

export default ShareButtons;
